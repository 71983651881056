<template>
  <div class="container mt-5" v-if="product">
    <div class="row">
      <div class="col-md-6">
        <div id="carouselProductImages" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div v-for="(image, index) in product.images" :key="index"
              :class="['carousel-item', { active: index === 0 }]">
              <img :src="image" class="d-block w-100" alt="Slika proizvoda" />
            </div>
          </div>
          <button class="carousel-control-prev custom-carousel-btn" type="button"
            data-bs-target="#carouselProductImages" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden"></span>
          </button>
          <button class="carousel-control-next custom-carousel-btn" type="button"
            data-bs-target="#carouselProductImages" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden"></span>
          </button>
        </div>
      </div>

      <div class="col-md-6">
        <h2>{{ product.name }}</h2>
        <p>{{ product.description }}</p>
        <p><strong>${{ product.price }}</strong></p>
        <button class="btn btn-primary" @click="addToCart(product)">Dodaj u košaricu</button>
      </div>
    </div>

    <div class="mt-5">
      <h4>Recenzije</h4>
      <div v-if="reviews.length > 0">
        <div v-for="review in reviews" :key="review.id" class="review-item">
          <div>
            <span v-html="getStarRating(review.rating)"></span>
          </div>
          <p>{{ review.comment }}</p>
        </div>
      </div>

      <div class="mt-3" v-if="isAuthenticated">
        <h5>Dodaj recenziju</h5>
        <div class="mb-3">
          <label for="rating" class="form-label">Ocjena:</label>
          <select v-model="newReview.rating" class="form-select" required>
            <option disabled value="">Odaberite ocjenu</option>
            <option v-for="n in 5" :key="n" :value="n">{{ n }} Zvijezda{{ n > 1 ? 'e' : '' }}</option>
          </select>
        </div>
        <div class="mb-3">
          <label for="comment" class="form-label">Komentar:</label>
          <textarea v-model="newReview.comment" id="comment" rows="3" class="form-control"
            placeholder="Napišite svoju recenziju" required></textarea>
        </div>
        <button class="btn btn-success" @click="submitReview">Pošalji recenziju</button>
      </div>
    </div>

    <div class="mt-5">
      <h3>Povezani proizvodi</h3>
      <div id="relatedProductsCarousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item" v-for="(chunk, index) in chunkArray(relatedProducts, isMobile ? 1 : 3)"
            :key="index" :class="{ active: index === 0 }">
            <div class="row">
              <div class="col-md-4" v-for="product in chunk" :key="product.id">
                <div class="card">
                  <img :src="product.images[0]" class="card-img-top" alt="Slika povezanog proizvoda">
                  <div class="card-body">
                    <h5 class="card-title">{{ product.name }}</h5>
                    <p class="card-text">${{ product.price }}</p>
                    <router-link :to="`/products/${product.id}`" class="btn btn-primary">Pogledaj proizvod</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev custom-carousel-btn" type="button"
          data-bs-target="#relatedProductsCarousel" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden"></span>
        </button>
        <button class="carousel-control-next custom-carousel-btn" type="button"
          data-bs-target="#relatedProductsCarousel" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden"></span>
        </button>
      </div>
    </div>
    <div style="height: 100px;"></div>
  </div>
</template>

<script>
import productService from '../services/productService';
import reviewService from '../services/reviewService';
import { useCartStore } from '../store/cart';
import { useStore } from '../store';


export default {
  name: 'ProductDetail',
  data() {
    return {
      product: null,
      relatedProducts: [],
      reviews: [],
      newReview: {
        rating: '',
        comment: ''
      },
      isMobile: false,
    };
  },
  computed: {
    isAuthenticated() {
      const store = useStore();
      return store.isAuthenticated;
    }
  },
  methods: {
    async fetchProduct() {
      const id = this.$route.params.id;
      const data = await productService.getProductById(id);
      this.product = data.data;
      await this.fetchRelatedProducts(this.product.categoryId);
      await this.fetchReviews(id);
    },
    async fetchReviews(productId) {
      const response = await reviewService.getReviewsByProductId(productId);
      this.reviews = response.data;
    },
    async fetchRelatedProducts(categoryId) {
      const data = await productService.getByCategoryId(categoryId);
      this.relatedProducts = data.data.filter(p => p.id !== this.product.id);
    },
    addToCart(product) {
      const cartStore = useCartStore();
      cartStore.addToCart(product);
    },
    getStarRating(rating) {
      const maxStars = 5;
      const filledStars = '★'.repeat(rating);
      const emptyStars = '☆'.repeat(maxStars - rating);
      return filledStars + emptyStars;
    },
    chunkArray(array, size) {
      const chunkedArray = [];
      for (let i = 0; i < array.length; i += size) {
        chunkedArray.push(array.slice(i, i + size));
      }
      return chunkedArray;
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    async submitReview() {
      if (!this.newReview.rating || !this.newReview.comment) {
        alert('Please fill in both the rating and comment.');
        return;
      }

      try {
        const reviewData = {
          productId: this.product.id,
          rating: this.newReview.rating,
          comment: this.newReview.comment,
        };
        await reviewService.createReview(reviewData);
        this.newReview = { rating: '', comment: '' };
        await this.fetchReviews(this.product.id);
      } catch (error) {
        console.error('Error submitting review:', error);
      }
    },
  },
  async created() {
    await this.fetchProduct();
    this.checkIsMobile();
    window.addEventListener('resize', this.checkIsMobile);
  },
  unmounted() {
    window.removeEventListener('resize', this.checkIsMobile);
  },
  watch: {
    '$route.params.id': 'fetchProduct',
  },
};
</script>

<style scoped>
.custom-carousel-btn {
  width: 50px;
  filter: invert(1);
}
</style>
