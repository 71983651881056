<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-4" v-for="product in filteredProducts" :key="product.id">
        <div class="card mb-4">
          <router-link :to="'/products/' + product.id" class="text-decoration-none">
            <img v-if="product.images && product.images.length" :src="product.images[0]"
              class="card-img-top fixed-image" alt="Product Image" />
            <div class="card-body">
              <h5 class="card-title">{{ product.name }}</h5>
              <p class="card-text">{{ product.description }}</p>
              <p class="card-text">${{ product.price }}</p>
            </div>
          </router-link>
          <div class=" m-2">
            <button class="btn btn-primary w-100" @click="addToCart(product)">Dodaj u košaricu</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useCartStore } from '../store/cart';

export default {
  name: 'ProductList',
  props: {
    filteredProducts: {
      type: Array,
      required: true,
    },
  },
  methods: {
    addToCart(product) {
      const cartStore = useCartStore();
      cartStore.addToCart(product);
    },
  },
};
</script>

<style scoped>
.fixed-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.card {
  cursor: pointer;
}

.text-decoration-none {
  text-decoration: none;
  color: inherit;
}

.card:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}
</style>
